<template>
  <div>
    <el-upload ref='upload' :action="uploadAction" list-type="picture-card" accept="image/*" :limit="imgLimit"
      :file-list="productImgs" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
      :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :on-exceed="handleExceed"
      :on-error="imgUploadError" :on-change="handleEditChange" :class="{hide:hideUploadEdit}">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="editForm.uploadingurl" alt />
    </el-dialog>
  </div>
  

</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        productImgs: [],
        imgLimit: 1, //文件个数
        hideUploadEdit: false,
        uploadAction: "#",
        editForm: {
          uploadingurl: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/3f7eb5b6-b193-4821-969f-d930d55ce260.png"
        }
      }
    },
    methods: {
      handleEditChange(file, fileList) {
        let vm = this
        vm.hideUploadEdit = fileList.length >= this.imgLimit
      },
      handleRemove(file, fileList) {
        //移除图片
        console.log(file, fileList);
        this.editForm.uploadingurl = ''
        let vm = this
        vm.hideUploadEdit = fileList.length >= this.imgLimit
        this.$refs.upload.clearFiles();
      },
      handlePictureCardPreview(file) {
        //预览图片时调用
        console.log(file);
        this.editForm.uploadingurl = file.url;
        this.dialogVisible = true;
      },

      beforeAvatarUpload(file) {
        //文件上传之前调用做一些拦截限制
        console.log(file);
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传广告图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 2MB!");
        }
        return isJPG && isLt2M;
      },
      handleAvatarSuccess(res, file) {
        //图片上传成功
        console.log(res);
        console.log(file);
        this.imageUrl = URL.createObjectURL(file.raw);
        this.editForm.uploadingurl = res.filePath
      },
      handleExceed(files, fileList) {
        //图片上传超过数量限制
        this.$message.error("上传图片不能超过1张!");
        console.log(files, fileList);
      },
      imgUploadError(err, file, fileList) {
        //图片上传失败调用
        console.log(file, fileList);
        console.log(err);
        this.$message.error("上传图片失败!");
      },
    }
  }
</script>

<style>
</style>
